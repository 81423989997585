<template>
  <div
    :class="[
      'container-' + organism.containerWidth,
      'organism site-map-cta relative isolate z-10',
      organism.siteMapCtaLayout,
    ]"
    :style="'background-color:' + organism.backgroundColor"
  >
    <img
      v-if="getImageSrc(organism.backgroundImage, 'large')"
      class="absolute inset-0 object-cover w-full h-full -z-10 mix-blend-multiply"
      :src="getImageSrc(organism.backgroundImage, 'large')"
      :style="'opacity:' + organism.backgroundImageOpacity"
      loading="lazy"
    />
    <div
      class="content lg:h-[228px] grid gap-8 grid-cols-1 lg:grid-cols-2 mx-auto max-w-[1220px] relative lg:-top-[10px] py-9 lg:py-0"
    >
      <div
        class="content-text flex items-center col-1 px-8 md:px-9 lg:pr-0 max-w-[610px] mx-auto lg:mx-0"
      >
        <h2
          class="font-figtree text-4xl md:text-[44px] text-white font-semibold leading-none text-center lg:text-left"
        >
          {{ organism.title1 }}
        </h2>
      </div>
      <div
        class="flex flex-col-reverse items-center justify-end px-8 content-button-image button-image-content lg:flex-row col-2 gap-8 lg:pr-9 xl:pr-0"
      >
        <MoleculesButtons
          v-if="organism.link1"
          :buttons="organism"
          :button-colors="buttonColors"
        />
        <img
          class="rounded-full w-[247px] aspect-square"
          :src="getImageSrc(organism.image, 'medium')"
          alt=""
          loading="lazy"
        />
      </div>
    </div>
  </div>
</template>

<style>
.site-map-cta {
  &.layout-image-button-text {
    .content-text {
      @apply order-last lg:pr-8 lg:pl-0;

      h2 {
        @apply lg:text-right;
      }
    }
    .content-button-image {
      @apply lg:flex-row-reverse;
    }
  }

  .buttons a {
    @apply uppercase text-[15px] font-medium px-7 py-3 whitespace-nowrap;
  }
}
</style>

<script setup>
const props = defineProps({
  organism: Object,
});

const buttonColors = computed(() => {
  const buttonColorsArray = [];
  if (props.organism.button1Color) {
    buttonColorsArray.push(props.organism.button1Color);
  }
  if (props.organism.button2Color) {
    buttonColorsArray.push(props.organism.button2Color);
  }
  if (props.organism.button3Color) {
    buttonColorsArray.push(props.organism.button3Color);
  }
  return buttonColorsArray;
});

onMounted(() => {
  if (props.organism.containerWidth === "full-width") {
    addFullWidthSupport();
  }
});
</script>
