<template>
  <div
    :class="[
      'container-full-width',
      'bg-[length:0_0] organism brand-window-box-overlay bg-center bg-no-repeat sm:bg-cover flex justify-center items-center py-4 sm:py-28 px-2 sm:px-4 min-h-0 sm:min-h-[623px]',
    ]"
    :style="
      'background-image: url(' + getImageSrc(organism.backgroundImage) + ');'
    "
  >
    <div
      class="text-center text-white overlay bg-bc-dark-blue/90 max-w-[623px]"
    >
      <div
        v-if="organism.text1"
        class="status font-figtree text-xl font-bold p-[6px]"
      >
        <span class="drop-shadow-text">{{ organism.text1 }}</span>
      </div>
      <div
        class="flex flex-col justify-center px-8 pt-4 pb-12 sm:px-10 content"
      >
        <div v-if="organism.collectionIcons" class="collections">
          <AtomsCollectionButton
            v-for="icon in organism.collectionIcons"
            :collection="icon"
            class="mr-2"
          />
        </div>
        <h3
          v-if="organism.title3"
          class="font-figtree text-[17px] font-bold uppercase text-bc-bright-blue mb-4"
        >
          {{ organism.title3 }}
        </h3>
        <h1
          v-if="organism.title1"
          class="mx-auto text-4xl font-semibold sm:text-6xl font-figtree drop-shadow-text"
        >
          <span class="block max-w-sm">{{ organism.title1 }}</span>
        </h1>
        <h2
          v-if="organism.title2"
          class="mx-auto mb-4 text-4xl font-semibold drop-shadow-text font-figtree sm:text-6xl"
        >
          {{ organism.title2 }}
        </h2>
        <div
          v-if="organism.description"
          class="max-w-xl mx-auto my-4 text-base content md:text-xl"
          v-html="organism.description"
        ></div>
        <MoleculesButtons
          class="lg:justify-center"
          :buttons="organism"
          :button-colors="buttonColors"
        />
      </div>
    </div>
  </div>
</template>

<style scoped>
.status {
  background-image: linear-gradient(101deg, #1b3f68 0%, #265a94 97%);
}
</style>

<script setup>
const props = defineProps({
  organism: Object,
});

onMounted(() => {
  if (props.organism.containerWidth === "full-width") {
    addFullWidthSupport();
  }
});

const buttonColors = computed(() => {
  const buttonColorsArray = [];
  if (props.organism.button1Color) {
    buttonColorsArray.push(props.organism.button1Color);
  }
  if (props.organism.button2Color) {
    buttonColorsArray.push(props.organism.button2Color);
  }
  if (props.organism.button3Color) {
    buttonColorsArray.push(props.organism.button3Color);
  }
  return buttonColorsArray;
});
</script>
