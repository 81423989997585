<template>
  <article
    v-if="!item.hideCommunity"
    :id="item.id"
    :class="[tabId ? tabId + '-item' : '', 'item pt-7 pb-9']"
    :data-collections="item.collections.join(' ')"
  >
    <header
      class="mb-5 grid grid-cols-[225px] sm:grid-cols-[225px_225px] lg:grid-cols-[225px] xl:grid-cols-[225px_1fr] lg:text-center xl:text-left justify-center items-center gap-7"
    >
      <figure class="relative">
        <div
          v-if="item.itemStatus"
          class="font-bold absolute top-0 w-full text-white uppercase status bg-bc-le-gray text-[11px] text-center p-2 font-figtree z-50"
        >
          {{ item.itemStatus }}
        </div>
        <NuxtLink :to="item.link">
          <div
            :style="{
              backgroundImage: `url(${item.thumbnailSrc})`,
              backgroundPosition: 'center bottom',
            }"
            class="h-full bg-cover w-full relative min-h-[175px]"
          >
            <!--<img
            :src="item.thumbnailSrc"
            :alt="item.thumbnailAlt"
            class="object-cover w-full h-full"
          />-->
          </div>
        </NuxtLink>
      </figure>
      <div class="flex flex-col item-text gap-1 min-h-[200px] justify-center">
        <p
          v-if="item.neighborhoodTitle"
          class="font-semibold leading-none text-black font-figtree"
        >
          {{ item.neighborhoodTitle }}
        </p>
        <NuxtLink class="title" :to="item.link">
          <h4
            class="font-figtree leading-tight font-bold text-[21px] text-bc-dark-blue text-left"
          >
            {{ item.title }}
          </h4>
        </NuxtLink>
        <p class="text-base text-left text-gray-500 details font-figtree">
          <span
            v-for="subtitle in item.subtitle"
            :class="[
              tabId === 'quick-move-in' && subtitle.includes('$')
                ? 'font-bold text-black'
                : '',
              'block',
            ]"
          >
            {{ subtitle }}
          </span>
        </p>

        <AtomsCollectionButton
          v-for="collection in item.collections"
          :collection="collection"
          disableShadow
        />
      </div>
    </header>
    <footer class="text-center body">
      <NuxtLink
        class="explore-button text-center py-[6px] inline-block text-[15px] font-medium font-figtree button-blue shadow rounded-md leading-none sm:leading-normal w-[190px] sm:w-[280px] max-w-full"
        :to="item.link"
      >
        {{ item.linkText }}
      </NuxtLink>
    </footer>
  </article>
</template>

<style>
.item {
  .item-text {
    @apply items-start;
  }

  .atom-collection-button {
    @apply mb-0 p-0 block mr-auto;

    img {
      @apply w-7 h-7;
    }
    span {
      @apply text-lg;
    }
  }
}
</style>

<script setup>
const props = defineProps({
  itemRawData: Object,
  tabId: String,
  plans: Array,
});

// Map data from query to standard data structure for use in template
const item = {};
switch (props.itemRawData.__typename) {
  case "Neighborhood":
    item.id = props.itemRawData.slug;
    item.title = props.itemRawData.title;
    item.link = props.itemRawData.uri;
    item.linkText = "EXPLORE COMMUNITY";
    item.itemStatus = getNeighborhoodStatusLabel(
      props.itemRawData.neighborhood.status
    );
    item.thumbnailSrc = getImageSrc(
      props.itemRawData.neighborhood.thumbnail,
      "medium"
    );
    item.thumbnailAlt = props?.itemRawData?.neighborhood?.thumbnail?.altText;
    item.hideCommunity = props?.itemRawData?.neighborhood?.hideCommunity;

    item.subtitle = [
      getNeighborhoodNavSubtitle(
        props.itemRawData.types,
        getRoundedPrice(props.itemRawData.basePrice),
        props.itemRawData.neighborhood.status
      ),
    ];
    item.collections = getCollectionsByNeighborhood(
      props.itemRawData.slug,
      props.plans,
      null,
      null,
      [props.itemRawData.collection?.collection]
    );
    //console.log(item.collections);
    //console.log(item);
    break;
  case "Model":
    item.id = props.itemRawData.slug;
    item.title = props.itemRawData.title;
    item.neighborhoodTitle = props?.itemRawData?.modelDetails?.neighborhood?.title;
    item.link = props.itemRawData.uri;
    item.linkText = "EXPLORE MODEL HOME";
    item.thumbnailSrc = getImageSrc(
      props?.itemRawData?.featuredImage?.node ??
      props?.itemRawData?.modelDetails?.neighborhood?.neighborhood?.thumbnail,
      "medium"
    );
    item.thumbnailAlt =
      props?.itemRawData?.featuredImage?.node.altText ??
      props?.itemRawData?.modelDetails?.neighborhood?.neighborhood?.thumbnail
        ?.altText;
    item.subtitle = [
      getNeighborhoodNavSubtitle(
        props?.itemRawData?.modelDetails?.neighborhood?.types,
        getRoundedPrice(props?.itemRawData?.modelDetails?.neighborhood?.basePrice),
        props?.itemRawData?.modelDetails?.neighborhood?.neighborhood?.status
      ),
    ];
    item.collections = [
      props?.itemRawData?.collection?.collection ??
        props?.itemRawData?.modelDetails?.floorplan?.floorplanDetails
          ?.collection,
    ];
    item.hideCommunity = props?.itemRawData?.modelDetails?.neighborhood?.neighborhood?.hideCommunity;
    break;
  case "Home":
    item.id = props.itemRawData.slug;
    item.title = props.itemRawData.title;
    item.neighborhoodTitle =
      props.itemRawData.qmiHomeDetails.neighborhood.title;
    item.link = props.itemRawData.uri;
    item.linkText = "EXPLORE HOME DETAILS";
    item.thumbnailSrc = getImageSrc(
      props?.itemRawData?.featuredImage?.node ??
        props.itemRawData.qmiHomeDetails.neighborhood.neighborhood.thumbnail,
      "medium"
    );
    item.thumbnailAlt =
      props?.itemRawData?.featuredImage?.node.altText ??
      props?.itemRawData?.qmiHomeDetails?.neighborhood?.neighborhood?.thumbnail
        ?.altText;
    item.subtitle = [
      props?.itemRawData?.qmiHomeDetails?.type,
      props?.itemRawData?.qmiHomeDetails?.bedrooms +
        " bed, " +
        props?.itemRawData?.qmiHomeDetails?.bathrooms +
        " bath",
      props?.itemRawData?.qmiHomeDetails?.finishedSqrFt.toLocaleString(
        "en-US"
      ) + " sq. ft",
      "$" + props?.itemRawData?.qmiHomeDetails?.price.toLocaleString("en-US"),
    ];
    item.collections = [
      props?.itemRawData?.collection?.collection ??
        props?.itemRawData?.qmiHomeDetails?.floorplan?.floorplanDetails
          ?.collection,
    ];
    item.hideCommunity = props?.itemRawData?.qmiHomeDetails?.neighborhood?.neighborhood?.hideCommunity;
    break;
}
</script>
